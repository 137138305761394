import React, { Suspense, lazy, useEffect, useState } from "react";
import "./App.scss";
import TopNav from "./Components/TopNav/TopNav";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import EventDetails from "./Components/EventDetails/EventDetails";
import NewsDetails from "./Components/NewsDetails/NewsDetails";
import TeamDetails from "./Components/TeamDetails/TeamDetails";
import UnsubscribePage from "./_Pages/Unsubscribe/UnsubscribePage";
import LoadingScreen from "./Components/LoadingScreen/LoadingScreen";
import axios from "axios";
import ReactGA from "react-ga4"; // Google Analytics

// Lazy load the pages
const Home = lazy(() => import("./_Pages/Home/Home"));
const Events = lazy(() => import("./_Pages/Events/Events"));
const News = lazy(() => import("./_Pages/News/News"));
const Join = lazy(() => import("./_Pages/Join/Join"));
const Team = lazy(() => import("./_Pages/Team/Team"));

// Component to handle Google Analytics tracking
function GoogleAnalyticsTracker() {
  const location = useLocation();

  useEffect(() => {
    const trackingId = "G-0ZNED4SV6H"; // Replace with your Google Analytics tracking ID
    ReactGA.initialize(trackingId);
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null; // This component doesn't render anything
}

function App() {
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  const [preloadedHomeImage, setPreloadedHomeImage] = useState(null);
  const [preloadedEventImage, setPreloadedEventImage] = useState(null);
  const [preloadedNewsImage, setPreloadedNewsImage] = useState(null);
  const [preloadedJoinImage, setPreloadedJoinImage] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Preload images
    const preloadImages = async () => {
      try {
        // Fetch and preload home image (post 289)
        const homeResponse = await axios.get(
          `${baseURL}/wp-json/wp/v2/posts/289`
        );
        const homeAcfFields = homeResponse.data.acf;
        const homeImage = new Image();
        homeImage.src = homeAcfFields.page_title_image;

        // Fetch and preload event image (post 466)
        const eventResponse = await axios.get(
          `${baseURL}/wp-json/wp/v2/posts/466`
        );
        const eventAcfFields = eventResponse.data.acf;
        const eventImage = new Image();
        eventImage.src = eventAcfFields.page_title_image;

        // Fetch and preload news image (post 623)
        const newsResponse = await axios.get(
          `${baseURL}/wp-json/wp/v2/posts/623`
        );
        const newsAcfFields = newsResponse.data.acf;
        const newsImage = new Image();
        newsImage.src = newsAcfFields.page_title_image;

        // Fetch and preload join image (post 627)
        const joinResponse = await axios.get(
          `${baseURL}/wp-json/wp/v2/posts/627`
        );
        const joinAcfFields = joinResponse.data.acf;
        const joinImage = new Image();
        joinImage.src = joinAcfFields.page_title_image;

        // Preload both images
        homeImage.onload = () =>
          setPreloadedHomeImage(homeAcfFields.page_title_image);
        eventImage.onload = () =>
          setPreloadedEventImage(eventAcfFields.page_title_image);
        newsImage.onload = () =>
          setPreloadedNewsImage(newsAcfFields.page_title_image);
        joinImage.onload = () =>
          setPreloadedJoinImage(joinAcfFields.page_title_image);

        setLoading(false); // Mark loading as complete after images are preloaded
      } catch (error) {
        console.error("Error preloading images", error);
        setLoading(false);
      }
    };

    preloadImages();
  }, [baseURL]);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Router>
      {/* Google Analytics Tracker */}
      <GoogleAnalyticsTracker />

      <TopNav />
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          <Route
            path="/"
            element={<Home preloadedImage={preloadedHomeImage} />}
          />
          <Route path="/team" element={<Team />} />
          <Route path="/team/:slug" element={<TeamDetails />} />
          <Route
            path="/events"
            element={<Events preloadedImage={preloadedEventImage} />}
          />
          <Route path="/events/:slug" element={<EventDetails />} />
          <Route
            path="/news"
            element={<News preloadedImage={preloadedNewsImage} />}
          />
          <Route path="/news/:slug" element={<NewsDetails />} />
          <Route
            path="/join"
            element={<Join preloadedImage={preloadedJoinImage} />}
          />
          <Route path="/unsubscribe" element={<UnsubscribePage />} />
        </Routes>
      </Suspense>
      <Footer />
    </Router>
  );
}

export default App;
