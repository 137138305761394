import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./EventDetails.scss"; // Assuming you have some styling for this component

const EventDetails = () => {
  const { slug } = useParams(); // Get the slug from the URL
  const [event, setEvent] = useState(null);
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    // Fetch the event details based on the slug
    axios
      .get(`${baseURL}/wp-json/wp/v2/posts?slug=${slug}&_embed`)
      .then((response) => {
        setEvent(response.data[0]); // Assuming response data is an array
      })
      .catch((error) => {
        console.error("Error fetching event details:", error);
      });
  }, [slug, baseURL]);

  if (!event) {
    return <div>Loading...</div>;
  }

  const formatDate = (dateString) => {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return new Date(year, month - 1, day).toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  };

  // Concatenate the address fields into a single string
  const fullAddress = `${event.acf.address_line_1}, ${event.acf.address_line_2}, ${event.acf.address_line_3}, ${event.acf.address_line_4}`;

  // URL encode the address for use in the Google Maps URL
  const encodedAddress = encodeURIComponent(fullAddress);

  // Construct the Google Maps link
  const googleMapsLink = `https://www.google.com/maps?q=${encodedAddress}`;

  return (
    <div className="event-details">
      <h1>{event.acf.event_name}</h1>
      <img
        className="header-image"
        src={event.acf.event_image}
        alt={event.acf.event_image_alt_tag}
        title={event.acf.event_image_title_tag}
      />
      <p>{event.acf.event_description}</p>

      <div className="date-container">
        <p>
          <strong>Starts:</strong> {formatDate(event.acf.event_start_date)}
        </p>
        <p>
          <strong>Ends:</strong> {formatDate(event.acf.event_end_date)}
        </p>
      </div>

      {/* Time Section */}
      {event.acf.event_start_time && (
        <div className="time-container">
          <p>
            <strong>Time:</strong> {event.acf.event_start_time}
            {event.acf.event_end_time && (
              <span> - {event.acf.event_end_time}</span>
            )}
          </p>
        </div>
      )}

      <div className="address-container">
        <p>
          <strong>Location:</strong>
          <br />
          {event.acf.address_line_1}
        </p>
        <p>{event.acf.address_line_2}</p>
        <p>{event.acf.address_line_3}</p>
        <p>{event.acf.address_line_4}</p>
      </div>

      <a target="_blank" href={googleMapsLink}>
        View on Google Maps
      </a>

      <div className="btn-container">
        <a className="primary-btn" target="_blank" href={event.acf.event_link}>
          Register
        </a>
      </div>

      <div className="event-description">
        <div
          dangerouslySetInnerHTML={{ __html: event.acf.event_full_description }}
        />
      </div>
    </div>
  );
};

export default EventDetails;
