import React, { useEffect, useState } from "react";
import xicon from "../../Media/Images/Icons/x-icon.svg";
import linkedin from "../../Media/Images/Icons/linkedin-icon.svg";
import "./Footer.scss";

const CACHE_DURATION = 600000; // 10 minutes in milliseconds

const Footer = () => {
  const [cachedXIcon, setCachedXIcon] = useState(null);
  const [cachedLinkedInIcon, setCachedLinkedInIcon] = useState(null);

  // Utility functions for caching
  const getCachedImage = (key) => {
    const cachedData = localStorage.getItem(key);
    if (cachedData) {
      const { src, timestamp } = JSON.parse(cachedData);
      const isExpired = Date.now() - timestamp > CACHE_DURATION;
      if (!isExpired) {
        return src;
      }
      localStorage.removeItem(key); // Remove expired cache
    }
    return null;
  };

  const setCachedImage = (key, src) => {
    const payload = { src, timestamp: Date.now() };
    localStorage.setItem(key, JSON.stringify(payload));
  };

  // Preload and cache images
  useEffect(() => {
    // Check if images are already cached
    const xIconCached = getCachedImage("x-icon");
    const linkedinIconCached = getCachedImage("linkedin-icon");

    if (!xIconCached) {
      const xIconImg = new Image();
      xIconImg.src = xicon;
      xIconImg.onload = () => setCachedImage("x-icon", xicon);
    } else {
      setCachedXIcon(xIconCached);
    }

    if (!linkedinIconCached) {
      const linkedinImg = new Image();
      linkedinImg.src = linkedin;
      linkedinImg.onload = () => setCachedImage("linkedin-icon", linkedin);
    } else {
      setCachedLinkedInIcon(linkedinIconCached);
    }
  }, []);

  return (
    <div className="footer">
      <div className="site-container">
        <div className="footer-container">
          <div className="col-1">
            <p>
              <a href="mailto:contact@techwestenglandadvocates.co.uk">
                Contact Us
              </a>
            </p>
          </div>
          <div className="col-2">
            <a href="https://twitter.com/TechSWEAdv" target="_blank">
              <img
                className="x-logo"
                src={cachedXIcon || xicon} // Use cached image if available
                alt="x logo"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/tech-west-england-advocates"
              target="_blank"
            >
              <img
                className="x-logo"
                src={cachedLinkedInIcon || linkedin} // Use cached image if available
                alt="linkedin logo"
              />
            </a>
          </div>
          <div className="col-3">
            <p>
              <a
                href="https://www.globaltechadvocates.org/privacy-policy/"
                target="_blank"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="details">
        <p className="address">
          <b>Future Space</b>, UWE North Gate, Filton Road, Stoke Gifford,
          Bristol, BS34 8RB
        </p>
        <p className="phone">
          <b>Tel</b> <a href="tell:4403300040868">+44 (0) 330 004 0868</a>
        </p>
        <p className="email">
          {" "}
          <b>Email</b>{" "}
          <a href="mailto:contact@techwestenglandadvocates.co.uk">
            contact@techwestenglandadvocates.co.uk
          </a>
        </p>
        <p className="company-no">
          <b>Company no.</b> 14599403
        </p>
        <p className="like-the-site">
          <b>Like the site?</b>{" "}
          <a href="mailto:contact@techwestenglandadvocates.co.uk">
            Contact us here to explore all digital design solutions available
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
