import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "./TeamDetails.scss";
import linkedin from "../../Media/Images/Icons/linkedin-icon.svg";

const TeamDetails = () => {
  const { slug } = useParams(); // Get the slug from the URL
  const [member, setMember] = useState(null);
  const baseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    // Fetch the event details based on the slug
    axios
      .get(`${baseURL}/wp-json/wp/v2/posts?slug=${slug}&_embed`)
      .then((response) => {
        setMember(response.data[0]); // Assuming response data is an array
      })
      .catch((error) => {
        console.error("Error fetching event details:", error);
      });
  }, [slug, baseURL]);

  if (!member) {
    return <div></div>;
  }

  return (
    <div className="team-details">
      <h1>{member.acf.name}</h1>
      <img
        className="header-image"
        src={member.acf.profile_picture}
        alt={member.acf.image_alt_tag}
        title={member.acf.image_title_tag}
      />
      {member.acf.linkedin_url && (
        <div className="linkedin-icon">
          <a target="_blank" href={member.acf.linkedin_url}>
            <img src={linkedin}></img>
          </a>
        </div>
      )}
      <h2>{member.acf.role}</h2>
      <p>{member.acf.about}</p>
    </div>
  );
};

export default TeamDetails;
